/*
Copyright 2024,2025 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SidebarUserSettingsTab_homeAllRoomsCheckbox {
    margin-left: 24px;

    & + div {
        margin-left: 48px;
    }
}

.mx_SidebarUserSettingsTab_icon {
    margin-right: var(--cpd-space-2x);
    margin-top: auto;
    margin-bottom: auto;
}

.mx_SidebarUserSettingsTab_checkbox label {
    display: flex;
}
