/*
Copyright 2024,2025 New Vector Ltd.
Copyright 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ManageRestrictedJoinRuleDialog_wrapper {
    .mx_Dialog {
        display: flex;
        flex-direction: column;
    }
}

.mx_ManageRestrictedJoinRuleDialog {
    width: 480px;
    color: $primary-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 0;
    height: 60vh;

    .mx_SearchBox {
        /* To match the space around the title */
        margin: 0 0 15px 0;
        flex-grow: 0;
    }

    .mx_ManageRestrictedJoinRuleDialog_content {
        flex-grow: 1;
    }

    .mx_ManageRestrictedJoinRuleDialog_noResults {
        display: block;
        margin-top: 24px;
    }

    .mx_ManageRestrictedJoinRuleDialog_section {
        &:not(:first-child) {
            margin-top: 24px;
        }

        > h3 {
            margin: 0;
            color: $secondary-content;
            font-size: $font-12px;
            font-weight: var(--cpd-font-weight-semibold);
            line-height: $font-15px;
        }

        .mx_ManageRestrictedJoinRuleDialog_entry {
            display: flex;
            margin-top: 12px;

            > div {
                flex-grow: 1;
            }

            .mx_ManageRestrictedJoinRuleDialog_entry_name {
                margin: 0 8px;
                font-size: $font-15px;
                line-height: 30px;
                flex-grow: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .mx_ManageRestrictedJoinRuleDialog_entry_description {
                margin-top: 8px;
                font-size: $font-12px;
                line-height: $font-15px;
                color: $tertiary-content;
            }
        }
    }

    .mx_ManageRestrictedJoinRuleDialog_section_spaces {
        .mx_BaseAvatar {
            margin-right: 12px;
        }
    }

    .mx_ManageRestrictedJoinRuleDialog_section_info {
        position: relative;
        border-radius: 8px;
        margin: 12px 0;
        padding: 8px 8px 8px 42px;
        background-color: $header-panel-bg-color;

        font-size: $font-12px;
        line-height: $font-15px;
        color: $secondary-content;

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: calc(50% - 8px); /* vertical centering */
            height: 16px;
            width: 16px;
            background-color: $secondary-content;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-image: url("@vector-im/compound-design-tokens/icons/info-solid.svg");
            mask-position: center;
        }
    }

    .mx_ManageRestrictedJoinRuleDialog_footer {
        margin-top: 20px;

        .mx_ManageRestrictedJoinRuleDialog_footer_buttons {
            display: flex;
            width: max-content;
            margin-left: auto;

            .mx_AccessibleButton {
                display: inline-block;

                & + .mx_AccessibleButton {
                    margin-left: 24px;
                }
            }
        }
    }
}
