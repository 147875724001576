.mx_HiddenMediaPlaceholder {
    border: none;
    width: 100%;
    height: 100%;
    inset: 0;

    /* To center the text in the middle of the frame */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    cursor: pointer;
    background-color: $header-panel-bg-color;

    > div {
        color: $accent;
        /* Icon alignment */
        display: flex;
        > svg {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.mx_EventTile:hover .mx_HiddenMediaPlaceholder {
    background-color: $background;
}
