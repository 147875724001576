/*
Copyright 2024,2025 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_SelectableDeviceTile {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.mx_SelectableDeviceTile_checkbox {
    flex: 1 0;

    > div {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: var(--cpd-space-1x);
    }
}
