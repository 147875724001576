/*
Copyright 2025 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_ReportRoomDialog {
    textarea {
        font: var(--cpd-font-body-md-regular);
        border: 1px solid var(--cpd-color-border-interactive-primary);
        background: var(--cpd-color-bg-canvas-default);
        border-radius: 0.5rem;
        padding: var(--cpd-space-3x) var(--cpd-space-4x);
    }
}
